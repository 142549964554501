<template>
  <section class="pa-3">
    <div class="d-flex align-start justify-space-between flex-wrap mb-10">
      <div class="d-flex">
        <h2 class="mb-6 mr-8">動画一覧</h2>

        <div class="d-flex">
          <v-btn
            large
            :dark="isWithinLimitedVideo"
            rounded
            :disabled="!isWithinLimitedVideo"
            color="#2196F3"
            :to="{ name: 'video_new' }"
            class="mr-5"
          >
            <v-icon class="mr-3"> mdi-video-plus-outline </v-icon>
            新規登録
          </v-btn>
          <p v-if="!isWithinLimitedVideo">
            最大アップロード動画件数である
            {{ videoLimit }}
            件を超えるため、新規に登録できません。
          </p>
        </div>
      </div>
    </div>

    <section>
      <div class="mb-4">
        全 {{ counts }} 動画 | 公開済み {{ publishedCounts }} 動画
      </div>

      <!-- ↓ 一括処理ボタン -->
      <!-- ↓ タグ検索 検索フィールド -->
      <VideoNewBulkAndSearchWidget
        :checked-list="checkedList"
        @reload-videos="
          getVideos();
          getVideoCounts();
          checkedList = [];
        "
      />

      <v-data-table
        v-model="checkedList"
        :headers="headers"
        :items="videos"
        show-select
      >
        <template v-slot:item.data-table-select="{ isSelected, select }">
          <v-simple-checkbox
            color="primary"
            :value="isSelected"
            @input="select($event)"
          />
        </template>
        <template v-slot:item.image="{ item }">
          <div
            class="thumbnailContainer"
            :style="{ width: `${thumbnailWidth}px` }"
          >
            <img :src="item.image" :alt="item.title" />
          </div>
        </template>
        <template v-slot:item.title="{ item }">
          <router-link
            :to="{
              name: 'video_edit',
              params: { videoId: item.id },
            }"
          >
            {{ item.title }}
          </router-link>
        </template>
        <template v-slot:item.viewCount="{ item }">
          {{ item.viewCount }}
        </template>
        <template v-slot:item.place="{ item }">
          {{ formatPlace(item.place) }}
        </template>
        <template v-slot:item.shotAt="{ item }">
          {{ formatShotAt(item.shotAt) }}
        </template>
        <template v-slot:item.user="{ item }">
          {{ item.user.name }}
        </template>
        <template v-slot:item.status="{ item }">
          {{ formatStatus(item.status) }}
        </template>
        <template v-slot:item.videoUrl="{ item }">
          <div
            v-if="
              item.cfMetadata?.status?.state === 'error' ||
              (item.cfMetadata?.status?.state !== 'ready' &&
                isUploadUrlExpiry(item.cfMetadata?.uploadExpiry))
            "
          >
            アップロード失敗（編集画面からアップロードをやり直してください）
          </div>
          <div v-else-if="item.cfMetadata?.status?.state === 'pendingupload'">
            アップロード待機中
          </div>
          <div
            v-else-if="
              ['queued', 'inprogress'].some(
                (i) => i === item.cfMetadata?.status?.state
              )
            "
          >
            エンコード中
          </div>
          <iframe
            v-else-if="item.videoReadyToStream"
            :src="item.videoUrl"
            style="border: none"
            height="180"
            width="320"
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
            allowfullscreen="true"
          ></iframe>
          <div v-else>準備中</div>
        </template>
      </v-data-table>
    </section>
  </section>
</template>

<script>
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(isSameOrAfter);
import { getData } from "../../axios";
import VideoNewBulkAndSearchWidget from "@/components/VideoNewBulkAndSearchWidget.vue";

export default {
  name: "VideoIndex",
  components: {
    VideoNewBulkAndSearchWidget,
  },
  data() {
    return {
      headers: [
        { text: "ID", align: "left", sortable: true, value: "id" },
        { text: "画像", align: "center", sortable: false, value: "image" },
        { text: "タイトル", align: "left", sortable: false, value: "title" },
        {
          text: "ビュー数",
          align: "right",
          sortable: false,
          value: "viewCount",
        },
        { text: "撮影場所", align: "left", sortable: false, value: "place" },
        { text: "撮影日時", align: "left", sortable: true, value: "shotAt" },
        { text: "登録者", align: "left", sortable: false, value: "user" },
        { text: "ステータス", align: "left", sortable: false, value: "status" },
        { text: "動画", align: "left", sortable: false, value: "videoUrl" },
      ],
      videos: [],
      counts: null,
      publishedCounts: null,
      checkedList: [],
      thumbnailWidth: 100,
    };
  },
  async beforeRouteEnter(_to, _from, next) {
    await next(async (vm) => {
      vm.getVideos();
      await vm.getVideoCounts();
    });
  },
  computed: {
    isWithinLimitedVideo() {
      return (
        this.counts < this.$store.getters["auth/client"].plan?.video?.limit
      );
    },
    videoLimit() {
      return this.$store.getters["auth/client"].plan?.video?.limit;
    },
  },
  methods: {
    formatPlace(value) {
      return value ? value : "-";
    },
    formatShotAt(value) {
      if (!value) {
        return "-";
      }
      return dayjs(value).format("YYYY.MM.DD - HH:mm");
    },
    formatStatus(value) {
      if (!value) {
        return "-";
      }
      return value === "published" ? "公開済み" : "下書き";
    },
    isUploadUrlExpiry(value) {
      return dayjs().isSameOrAfter(dayjs(value));
    },
    getVideos() {
      // this.loading = true;
      // const params = Object.assign({}, this.params);
      // if (this.client) {
      // this.getPhotosCounts();
      // params["per_page"] = this.client.perPage;
      getData("videos").then((res) => {
        this.videos = res.data.map((video) => {
          [
            ["client_id", "clientId"],
            ["image_width", "imageWidth"],
            ["image_height", "imageHeight"],
            ["location_accuracy", "locationAccuracy"],
            ["license_description", "licenseDescription"],
            ["enable_location_opendata", "isEnabledLocationOD"],
            ["shot_at_accuracy", "shotAtAccuracy"],
            ["view_count", "viewCount"],
            ["shot_at", "shotAt"],
            ["video_ready_to_stream", "videoReadyToStream"],
            ["video_token_expires_at", "videoTokenExpiresAt"],
            ["view_count_updated_at", "viewCountUpdatedAt"],
            ["video_token", "videoToken"],
            ["video_url", "videoUrl"],
            ["video_name", "videoName"],
            ["cf_metadata", "cfMetadata"],
          ].forEach((f) => {
            video[f[1]] = video[f[0]];
            delete video[f[0]];
          });
          return video;
        });
      });
    },
    async getVideoCounts() {
      const res = await getData("videos/counts");
      this.counts = res.data.counts;
      this.publishedCounts = res.data.publishedCounts;
    },
  },
};
</script>
