var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pa-3"},[_c('div',{staticClass:"d-flex align-start justify-space-between flex-wrap mb-10"},[_c('div',{staticClass:"d-flex"},[_c('h2',{staticClass:"mb-6 mr-8"},[_vm._v("動画一覧")]),_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-5",attrs:{"large":"","dark":_vm.isWithinLimitedVideo,"rounded":"","disabled":!_vm.isWithinLimitedVideo,"color":"#2196F3","to":{ name: 'video_new' }}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v(" mdi-video-plus-outline ")]),_vm._v(" 新規登録 ")],1),(!_vm.isWithinLimitedVideo)?_c('p',[_vm._v(" 最大アップロード動画件数である "+_vm._s(_vm.videoLimit)+" 件を超えるため、新規に登録できません。 ")]):_vm._e()],1)])]),_c('section',[_c('div',{staticClass:"mb-4"},[_vm._v(" 全 "+_vm._s(_vm.counts)+" 動画 | 公開済み "+_vm._s(_vm.publishedCounts)+" 動画 ")]),_c('VideoNewBulkAndSearchWidget',{attrs:{"checked-list":_vm.checkedList},on:{"reload-videos":function($event){_vm.getVideos();
        _vm.getVideoCounts();
        _vm.checkedList = [];}}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.videos,"show-select":""},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function({ isSelected, select }){return [_c('v-simple-checkbox',{attrs:{"color":"primary","value":isSelected},on:{"input":function($event){return select($event)}}})]}},{key:"item.image",fn:function({ item }){return [_c('div',{staticClass:"thumbnailContainer",style:({ width: `${_vm.thumbnailWidth}px` })},[_c('img',{attrs:{"src":item.image,"alt":item.title}})])]}},{key:"item.title",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
            name: 'video_edit',
            params: { videoId: item.id },
          }}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.viewCount",fn:function({ item }){return [_vm._v(" "+_vm._s(item.viewCount)+" ")]}},{key:"item.place",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatPlace(item.place))+" ")]}},{key:"item.shotAt",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatShotAt(item.shotAt))+" ")]}},{key:"item.user",fn:function({ item }){return [_vm._v(" "+_vm._s(item.user.name)+" ")]}},{key:"item.status",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatStatus(item.status))+" ")]}},{key:"item.videoUrl",fn:function({ item }){return [(
            item.cfMetadata?.status?.state === 'error' ||
            (item.cfMetadata?.status?.state !== 'ready' &&
              _vm.isUploadUrlExpiry(item.cfMetadata?.uploadExpiry))
          )?_c('div',[_vm._v(" アップロード失敗（編集画面からアップロードをやり直してください） ")]):(item.cfMetadata?.status?.state === 'pendingupload')?_c('div',[_vm._v(" アップロード待機中 ")]):(
            ['queued', 'inprogress'].some(
              (i) => i === item.cfMetadata?.status?.state
            )
          )?_c('div',[_vm._v(" エンコード中 ")]):(item.videoReadyToStream)?_c('iframe',{staticStyle:{"border":"none"},attrs:{"src":item.videoUrl,"height":"180","width":"320","allow":"accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;","allowfullscreen":"true"}}):_c('div',[_vm._v("準備中")])]}}]),model:{value:(_vm.checkedList),callback:function ($$v) {_vm.checkedList=$$v},expression:"checkedList"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }